import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useState } from "react";
import axios from "axios";

import {
  useCreateContactFormMutation
} from "../../api/unarApiSlice"

const Contact = () => {
  return (
    <>
      <Header />
      <Contact_us />
      <Footer />
    </>
  )
}

export default Contact;

export const Contact_us = () => {
  // console.log("Contact us form");

  // ! posting contactus form
  const [createContact] = useCreateContactFormMutation();

  // const [formValue, setformValue] = useState({
  //       email: '',
  //       name: '',
  //       message:''
  //     });

  const [formValue, setformValue] = useState('')

  const registerContact = (e) => {
    setformValue(formValue => {
      return {
        ...formValue,
        [e.target.name]: e.target.value,
      }
    })
  }

  const submitForm = (e) => {
    e.preventDefault();
    createContact(formValue);
    setformValue({
      "name": "",
      "email": "",
      "message": ""
    })
    alert("Form Submitted")
  }



  return (
    <>
      {/* <!-- ***** Contact Area Starts ***** --> */}
      <div class="contact-us">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.7807225345923!2d85.27895471459163!3d27.693171382797694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb187a97f390b1%3A0xec3f47092df0d4ca!2sKalanki%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1668664687780!5m2!1sen!2snp" width="100%" height="400px" >
                </iframe>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="section-heading">
                <h2>Say Hello. Don't Be Shy!</h2>
                <span>lets know about each other </span>
              </div>
              <form >
                <div class="row">
                  <div class="col-lg-6">
                    <fieldset>
                      <input
                        name="name"
                        type="text"
                        id="name"
                        placeholder="Your name"
                        required=""
                        value={formValue.name}
                        onChange={registerContact}
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-6">
                    <fieldset>
                      <input
                        name="email"
                        type="text"
                        id="email"
                        placeholder="Your email"
                        required=""
                        value={formValue.email}
                        onChange={registerContact}
                      />
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        rows="6" id="message"
                        placeholder="Your message"
                        required=""
                        value={formValue.message}
                        onChange={registerContact}></textarea>
                    </fieldset>
                  </div>
                  <div class="col-lg-12">
                    <fieldset>
                      <button type="submit" id="form-submit"
                        onClick={(e) => submitForm(e)} class="main-dark-button"><i class="fa fa-paper-plane"></i></button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
