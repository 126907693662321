import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../baseURL'

export const unarApiSlice = createApi({
    reducerPath: 'unarApiSlice',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['UnarStore'],
    endpoints: (builder) => ({

        // !  POST contact form
        createContactForm: builder.mutation({
            query: (contactForm) => {
                console.log("Contact form", contactForm);
                return {
                    url: `user`,
                    method: 'POST',
                    body: contactForm,
                    headers: {
                        'Content-type': 'application/json; charset = UTF-8',
                    }
                }
            },
            invalidatesTags: ['UnarStore']
        }),

        // !  POST Newsletter
        createNewsLetter: builder.mutation({
            query: (newsLetter) => {
                console.log("Contact form", newsLetter);
                return {
                    url: `apinewsletter/`,
                    method: 'POST',
                    body: newsLetter,
                    headers: {
                        'Content-type': 'application/json; charset = UTF-8',
                    }
                }
            },
            invalidatesTags: ['UnarStore']
        }),

    })
})

export const {
    useCreateContactFormMutation,
    useCreateNewsLetterMutation
} = unarApiSlice;