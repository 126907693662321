import React, { useEffect, useState } from 'react';
import { useParams, Link} from 'react-router-dom';
import Footer from '../components/footer';
import Header from '../components/header';
import axios from 'axios';

const Detail =()=>{
    return(
        <>
    <Header />
    <DetailPage />
    <Footer />
    </>
        )

}

const DetailPage =()=>{
    const Slug= useParams();
    const Myslug = Slug['slug'];

    const [Detailpage, setDetailpage] = useState([]);
    console.log(Detailpage)

    useEffect(()=>
    {
        axios.get(`https://api.unarstore.com/apiproduct/?product_slug=${Myslug}`)
    .then((res)=>{
        console.log(res.data);
        setDetailpage(res.data);
    })
    .catch((err)=>{
        console.log(err);
    })
    },[])
    return(
        <>
        <section class="section" id="product">
        {Detailpage.map(Detailpage=>(
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                <div class="left-images">
                    <img src={Detailpage.product_frontimage} alt=""/>
                    {/* <img src={Detailpage.product_sideimage} alt="" /> */}
                    <img src={Detailpage.product_backimage} alt="" />
                </div>
            </div>
            <div class="col-lg-4">
                <div class="right-content">
                    <h4>{Detailpage.product_name}</h4>
                    {/* <ul class="stars">
                        <li><i class="fa fa-star"></i></li>
                        <li><i class="fa fa-star"></i></li>
                        <li><i class="fa fa-star"></i></li>
                        <li><i class="fa fa-star"></i></li>
                        <li><i class="fa fa-star"></i></li>
                    </ul> */}
                    <span>{Detailpage.product_category_name}/{Detailpage.product_subcategory_name}</span>
                    <div class="quote">
                        <i class="fa fa-quote-left"></i><p>{Detailpage.product_description1}</p>
                    </div>
                    <div class="quote">
                        <i class="fa fa-quote-left"></i><p>{Detailpage.product_description2}</p>
                    </div>
                    <div class="quote">
                        <i class="fa fa-quote-left"></i><p>{Detailpage.product_description3}</p>
                    </div>
                    <div class="quantity-content">
                        <div class="left-content">
                            <h6>Availability: In Stock</h6>
                        </div>
                        <div class="right-content">
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        ))}
    </section>
    </>
    )
}

export default Detail