import React,{ useState} from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useParams, Link} from 'react-router-dom';
import { useEffect } from "react";
import axios from "axios"

const Category = ()=>{
    return(
        <>
        <Header/>
        <CategoryPage/>
        <Footer/>
        </>
    )
}
const CategoryPage =()=>{
    const cat_id = useParams();

    const New_id = cat_id['category_id'];

    const [cats,setcats] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.unarstore.com/apiproduct/?category_id=${New_id}`)
        .then((res)=>{
            console.log(res.data);
            setcats(res.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    },[])
        return(
            <>
        <div class="page-heading" id="top">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="inner-content">
                    {/* {Category && Category.category_name} */}
                        {/* <h2>{data.category_name}</h2> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
            <div class="row">
            {
                cats.map(dats=>(
                    <div class="col-md-3 col-sm-4">
							<div class="single-new-arrival">
                            <Link to={`/Detail_page/${dats.product_slug}`} className='underline-text'>
								<div class="single-new-arrival-bg">
                                <img src={dats.product_preview_image} alt="new-arrivals images" />
									<div class="single-new-arrival-bg-overlay"></div>
									<div class="new-arrival-cart">
										<p>
											<span class="lnr lnr-cart"></span>
											<a href="#">add <span>to </span> cart</a>
										</p>
										<p class="arrival-review pull-right">
											<span class="lnr lnr-heart"></span>
											<span class="lnr lnr-frame-expand"></span>
										</p>
									</div>
								</div>
								<h4><a href="#">{dats.product_name}</a></h4>
								<p class="arrival-product-price">$65.00</p>
                                </Link>
                                </div>
						</div>
                ))
            }
            </div>
            </>
        )
}

export default Category;