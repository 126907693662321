import React from "react";
import close from "../../static/images/close.png";
import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../static/images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [Category, setCategory] = useState("");

  useEffect(() => {
    axios
      .get(`https://api.unarstore.com/apicategories/`)
      .then((response) => {
        setCategory(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [show, setShow] = useState(false);
  return (
    <header class="header-area header-sticky">
      <div class="container">
        <div >
          <div class="col-12">
            <nav class="main-nav">
              {/* <!-- ***** Logo Start ***** --> */}
              <Link to="/" class="logo">
                <img src={logo} height="70px" />
              </Link>
              {/* <!-- ***** Logo End ***** --> */}
              {/* <!-- ***** Menu Start ***** --> */}
              <ul class="nav" style={show===true?{display:"block"}:{display:"none"}}>
                {Category &&
                  Category.map((Cat) => (
                    <li class="submenu" key={Cat.category_id}>
                      <Link to={`/cat/category/${Cat.category_id}`}>
                        {Cat.category_name}
                      </Link>
                      <ul>
                        <li>
                          <a>
                            <Sub_cat {...Cat} />
                          </a>
                        </li>
                      </ul>
                    </li>
                  ))}
                <li class="scroll-to-section">
                  <Link to="/Contact_us">Contact Us</Link>
                </li>
              </ul>
              <a class={show===true?"menu-trigger active":"menu-trigger"} onClick={!show===true?()=>setShow(true):()=>setShow(false)}>
                <span>Menu</span>
              </a>
              {/* <!-- ***** Menu End ***** --> */}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};


const Sub_cat = (props) => {
  const [SubCat, setSubCat] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://api.unarstore.com/apisubcategories/?category_id=${props.category_id}`
      )
      .then((res) => {
        console.log(res.data[0]);
        setSubCat(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {SubCat.map((sub) => (
        <Link to={`/sub/category/${sub.subcategory_id}`}>
          {sub.subcategory_name}
        </Link>
      ))}
    </>
  );
};

export default Header;
