import react from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import logo from "../../static/images/logo.png";


const About = ()=>{
    return(
        <>
        <Header/>
        <About_us/>
        <Footer/>
        </>
    )
}

const About_us = ()=>{
    return(
        <>
        {/* //  <!-- ***** About Area Starts ***** --> */}
    <div class="about-us">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-image">
                    <img src={logo} alt="" />
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content">
                    <h4>About Us</h4>
                    <span></span>
                    {/* <div class="quote">
                        <i class="fa fa-quote-left"></i><p>Immortalizing the knot of relation</p>
                    </div> */}
                    <p>Unar is a bag and apparels company which started its operation from September-9 2019. We are a Nepal based company who specialize in manufacturing comfortable, durable and affordable bags for all age groups and activities. All the claims that we make about the quality of our backpacks are due to a systematic manufacturing process and positive customer feedback. Customer satisfaction is our one and only motto.</p>
                    <ul>
                    <li><a href="https://www.facebook.com/unarandcompany" target={"_blank"}><i class="fa-brands fa-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/_unar__/" target={"_blank"}><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-whatsapp"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- ***** About Area Ends ***** --> */}

{/* <!-- ***** Our Team Area Starts ***** --> */}
{/* <section class="our-team">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-heading">
                    <h2>Our Amazing Team</h2>
                    <span>Hello friends this is our team</span>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="thumb">
                        <div class="hover-effect">
                            <div class="inner-content">
                                <ul>
                                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <img src="assets/images/team-member-01.jpg" />
                    </div>
                    <div class="down-content">
                        <h4>Ragnar Lodbrok</h4>
                        <span>Product Caretaker</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="thumb">
                        <div class="hover-effect">
                            <div class="inner-content">
                                <ul>
                                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <img src="assets/images/team-member-02.jpg" />
                    </div>
                    <div class="down-content">
                        <h4>Ragnar Lodbrok</h4>
                        <span>Product Caretaker</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="team-item">
                    <div class="thumb">
                        <div class="hover-effect">
                            <div class="inner-content">
                                <ul>
                                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                    <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <img src="assets/images/team-member-03.jpg" />
                    </div>
                    <div class="down-content">
                        <h4>Ragnar Lodbrok</h4>
                        <span>Product Caretaker</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}
{/* <!-- ***** Our Team Area Ends ***** --> */}
</>
    )
}
export default About;