import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import mens from "../../static/images/mens.jpg"
import womens from "../../static/images/womens.jpg"
import accessories from "../../static/images/accessories.jpg"
import carousel from "../../static/images/carousel.jpg"
import carousel1 from "../../static/images/carousel1.jpg"
import carousel2 from "../../static/images/carousel2.jpg"
import explore from "../../static/images/explore.jpg"
import section from "../../static/images/section.jpeg"
import explore2 from "../../static/images/explore2.jpg"
import bag from "../../static/images/bag.png"
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
import { useCreateNewsLetterMutation } from "../../api/unarApiSlice";

const Home = () => {
    return (
        <>
            <Header />
            <Home_page />
            <Footer />
        </>
    )
}
const Home_page = () => {

    // ! Newsletter creation
    const [newsLetterPostGardeu] = useCreateNewsLetterMutation();

    const [Data, setData] = useState([]);

    useEffect(() => {
        axios.get(`https://api.unarstore.com/apiproduct/?is_featured=true`)
            .then((res) => {
                console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const [newsletter, setNewsLetter] = useState('')

    const newsLetterChange = (e) => {
        setNewsLetter(newsletter => {
            return {
                ...newsletter,
                [e.target.name]: e.target.value,
            }
        })
    }

    const submitNewsLetter = (e) => {
        e.preventDefault();
        newsLetterPostGardeu(newsletter);
        setNewsLetter({
            "id":"",
            "name": "",
            "email": ""
        })
        alert("Subscribed to newsletter")
    }
    return (
        <>
 <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={carousel}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={carousel1}
          alt="Second slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={carousel2}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
            {/* Main Banner Area Starts */}
            <div class="main-banner" id="top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-content">
                                <div class="thumb">
                                    <div class="inner-content">
                                        <h4>We Are Unar</h4>
                                        <span>Awesome, clean &amp; nice design backpacks</span>
                                        <div class="main-border-button">
                                            <a href="#">Purchase Now!</a>
                                        </div>
                                    </div>
                                    <img src={section} alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="right-content">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Men</h4>
                                                    <span>Best Clothes For Men's</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Men</h4>
                                                        <p>Best quality clothes for men</p>
                                                        <div class="main-border-button">
                                                            <a href="#">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={mens} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Women</h4>
                                                    <span>Best Clothes For Women</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Women</h4>
                                                        <p>Best quality clothes for women</p>
                                                        <div class="main-border-button">
                                                            <a href="#">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={womens} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Bagpack</h4>
                                                    <span>Best Bagpack's</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Bagpack</h4>
                                                        <p>Best quality bagpack</p>
                                                        <div class="main-border-button">
                                                            <a href="#">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={bag} height={"270px" } width={"385px"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="right-first-image">
                                            <div class="thumb">
                                                <div class="inner-content">
                                                    <h4>Accessories</h4>
                                                    <span>Best Trend Accessories</span>
                                                </div>
                                                <div class="hover-content">
                                                    <div class="inner">
                                                        <h4>Accessories</h4>
                                                        <p>best quality trending accessories</p>
                                                        <div class="main-border-button">
                                                            <a href="#">Discover More</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={accessories} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Banner Area Ends */}

            {/* new arrivals section starts*/}
            <div class="container">
                <div class="section-header">
                    <h2>New arrivals</h2>
                </div>
                <div class="new-arrivals-content">
                    <Feature_products />
                </div>
            </div>
            {/*  new arrivals section ends*/}

            {/* Explore Area Starts */}
            <section class="section" id="explore">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="left-content">
                                <h2>Explore Our Products</h2>
                                {/* <span>we are unar this is unar </span>
                                <div class="quote">
                                    <i class="fa fa-quote-left"></i><p>Make it simple but significant</p>
                                </div> */}
                                <p>Our product are top quality products made with the high quality.</p>
                                <p>Our bags are top of the line high quality products. Made up of high quality cordura with good quality addons. Our bags are Stylish, Modern and Comfortable to carry and won’t break your bank. </p>
                                <div class="main-border-button">
                                    <a href="#">Discover More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="right-content">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="leather">
                                            <h4>Rollen Bags</h4>
                                            <span>Latest Collection</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="first-image">
                                            <img src={explore} alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="second-image">
                                            <img src={explore2} alt="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="types">
                                            <h4>Different Types</h4>
                                            <span>Over 10 Products</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Explore Area Ends */}

            {/* Newsletter Area Starts */}
            <div class="subscribe">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="section-heading">
                                <h2>By Subscribing To Our Newsletter You Can Get 30% Off</h2>
                                <span>Subscribe the newsletter and get 30% off</span>
                            </div>
                            <form id="subscribe" action="" method="get">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <fieldset>
                                            <input
                                                name="name"
                                                type="text"
                                                id="name"
                                                placeholder="Your Name" required=""
                                                value={newsletter.name}
                                                onChange={newsLetterChange}
                                            />
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-5">
                                        <fieldset>
                                            <input
                                                name="email"
                                                type="text"
                                                id="email"
                                                pattern="[^ @]*@[^ @]*" placeholder="Your Email Address"
                                                required=""
                                                value={newsletter.email}
                                                onChange={newsLetterChange}
                                            />
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-2">
                                        <fieldset>
                                            <button
                                                type="submit"
                                                onClick={(e) => submitNewsLetter(e)}
                                                id="form-submit" class="main-dark-button">
                                                <i class="fa fa-paper-plane">
                                                </i>
                                            </button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-4">
                            <div class="row">
                                <div class="col-6">
                                    <ul>
                                        <li>Phone:<br /><span>9851237871</span></li>
                                    </ul>
                                </div>
                                <div class="col-6">
                                    <ul>
                                        {/* <li>Work Hours:<br /><span>07:30 AM - 9:30 PM Daily</span></li> */}
                                        <li>Email:<br /><span>unarco084@gmail.com</span></li>
                                        <li>Social Media:<br /><span><a href="https://www.facebook.com/unarandcompany" target={"_blank"}>Facebook</a>, <a href="https://www.instagram.com/_unar__/" target={"_blank"}>Instagram</a>, </span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Newsletter Area Ends */}
        </>
    )
}
const Feature_products = () => {
    //get request for feature management
    const [Feature, setFeature] = useState([]);

    useEffect(() => {
        axios.get(`https://api.unarstore.com/apiproduct/?is_featured=true`)
            .then((res) => {
                console.log(res.data);
                setFeature(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])
    //end of get req
    return (
        <div class="row">
            {
                Feature.map(dats => (
                    <div class="col-md-3 col-sm-4">
                        <div class="single-new-arrival">
                            <Link to={`/Detail_page/${dats.product_slug}`} className='underline-text'>
                                <div class="single-new-arrival-bg">
                                    <img src={dats.product_preview_image} alt="new-arrivals images" height={102} width={132}/>
                                    <div class="single-new-arrival-bg-overlay"></div>
                                    <div class="new-arrival-cart">
                                        <p>
                                            <span class="lnr lnr-cart"></span>
                                            <a href="#">add <span>to </span> cart</a>
                                        </p>
                                        <p class="arrival-review pull-right">
                                            <span class="lnr lnr-heart"></span>
                                            <span class="lnr lnr-frame-expand"></span>
                                        </p>
                                    </div>
                                </div>
                                <h4><a href="#">{dats.product_name}</a></h4>
                            </Link>
                        </div>
                    </div>
                ))
            }
        </div>

    )
}
export default Home;
