import React from "react";
import close from "../../static/images/close.png"
import { useParams, Link} from 'react-router-dom';
import logo from "../../static/images/logo.png";


const Footer = () =>{
    return(
        <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="first-item">
                        <div class="logo">
                            <img src={logo} alt="unar logo" height="100px"/>
                        </div>
                        <ul>
                            <li><a href="#">Kalanki Kathmandu</a></li>
                            <li><a href="#">unarco084@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h4>Shopping &amp; Categories</h4>
                    <ul>
                        <li><a href="#">Men’s </a></li>
                        <li><a href="#">Women’s </a></li>
                        <li><a href="#">Bagpack's </a></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><a href="/">Homepage</a></li>
                        <li><Link to={`/About_us`}>About Us</Link></li>
                        <li><a href="#">Help</a></li>
                        <li><Link to={`/Contact_us`}>Contact Us</Link></li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4>Help &amp; Information</h4>
                    <ul>
                        <li><a href="#">Help</a></li>
                        <li><a href="#">FAQ's</a></li>
                        <li><a href="#">Shipping</a></li>
                        <li><a href="#">Tracking ID</a></li>
                    </ul>
                </div>
                <div class="col-lg-12">
                    <div class="under-footer">
                        <ul>
                            <li><a href="https://www.facebook.com/unarandcompany" target={"_blank"}><i class="fa-brands fa-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/_unar__/" target={"_blank"}><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#" target={"_blank"}><i class="fa-brands fa-whatsapp"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer;