import { Route } from "react-router";
import { Routes } from "react-router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import Home from "../template/pages/homepage";
import Contact from "../template/pages/contactus";
import About from "../template/pages/aboutus";
import Detail from "../template/pages/detailpage";
import Category from "../template/pages/cat";
import SubCategory from "../template/pages/subcat";
const Router = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element = {<Home/>} />
                <Route path="/Contact_us" element={<Contact />} />
                <Route path="/About_us" element={<About />} />
                <Route path="/Detail_page/:slug" element={<Detail />} />
                <Route path='/sub/category/:subcategory_id' element={<SubCategory />} />
                <Route path='/cat/category/:category_id' element={<Category />} />
            </Routes>
        </HashRouter>
    )
}
export default Router;