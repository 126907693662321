import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { unarApiSlice } from "./api/unarApiSlice";

export const store = configureStore({
    reducer: {
        [unarApiSlice.reducerPath]:unarApiSlice.reducer,
    },
    middleware:(getDefaultMiddleware) => 
        getDefaultMiddleware().concat(
            unarApiSlice.middleware,
        ),
        devTools:true
})

//! Refetch garnu paryo bhanne ko lagi
setupListeners(store.dispatch)