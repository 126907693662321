import logo from './logo.svg';
import './App.css';
import './static/css/style.css'
import './static/css/lightbox.css'
import Router from './route/router';
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="App">
                <Helmet>
                <meta charSet="utf-8" />
                <title>UNAR</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <meta name="description" content="UNAR" />
            </Helmet>
      <Router/>
    </div>
  );
}

export default App;
